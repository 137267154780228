import React from "react";
import EventForm from "../../components/event-form";

export default function CreateEvent(props) {
  const query = `
      mutation createEvent(
        $eventCall: String!, 
        $title: String!, 
        $description: String, 
        $startTime: String!, 
        $endTime: String!, 
        $slotLength: Int!, 
        $modes: [String!]!, 
        $bands: [String!]!) 
      {
        createEvent(
          eventCall: $eventCall
          title: $title
          description: $description
          startTime: $startTime
          endTime: $endTime
          slotLength: $slotLength
          modes: $modes
          bands: $bands
        ) {
          eventID
        }
      }
    `;

  return <EventForm query={query} />;
}
