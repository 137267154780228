const initState = {
  users: [],
  loading: false,
  currentCallsign: null,
};

const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
const SET_CURRENT_CALLSIGN = "SET_CURRENT_CALLSIGN";

export function setCurrentCallsign(payload) {
  return {
    type: SET_CURRENT_CALLSIGN,
    payload,
  };
}

export function deleteUserSuccess(payload) {
  return {
    type: DELETE_USER_SUCCESS,
    payload,
  };
}

export function editUserSuccess(payload) {
  return {
    type: EDIT_USER_SUCCESS,
    payload,
  };
}

const LOADING_USERS = "LOADING_USERS";
export function loadingUsers() {
  return {
    type: LOADING_USERS,
  };
}

const LOAD_USERS_FAILURE = "LOADING_USERS";
export function loadUsersFailure() {
  return {
    type: LOAD_USERS_FAILURE,
  };
}

const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export function loadUsersSuccess(payload) {
  return {
    type: LOAD_USERS_SUCCESS,
    payload,
  };
}

export default function usersReducer(state = initState, action) {
  switch (action.type) {
    case LOADING_USERS:
      return {
        ...state,
        loading: true,
      };

    case LOAD_USERS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case EDIT_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.callsign === action.payload.callsign) {
            return action.payload;
          } else {
            return user;
          }
        }),
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => {
          return user.callsign !== action.payload.callsign;
        }),
      };

    case SET_CURRENT_CALLSIGN:
      return {
        ...state,
        currentCallsign: action.payload,
      };

    default:
      return state;
  }
}
