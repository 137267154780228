import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import {
  isValidLoginUser,
  validateLoginUserProp,
} from "../../shared/login-user-schema";
import { callAPI } from "../../helpers/utils";
import { loginSuccess } from "../../store/auth-reducer";
import { useDispatch } from "react-redux";

export default function Login(props) {
  const [user, setUser] = useState({
    callsign: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    callsign: null,
    password: null,
  });

  const [apiErrors, setApiErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationError = Object.values(errors).reduce((prev, curr) => {
    return prev || curr !== null;
  }, false);
  const formError =
    !isValidLoginUser(user) || validationError || apiErrors.length > 0;

  const navigate = useNavigate();

  function login() {
    const query = `
      mutation login(
        $callsign: ID!, 
        $password: String!) {
          login(
            callsign: $callsign
            password: $password
          ) {
            callsign
            email
            firstName
            lastName
            role
            events {
              eventID
              eventRole
            }
          }
       } 
    `;
    setLoading(true);
    callAPI(
      query,
      user,
      (data) => {
        setLoading(false);
        setApiErrors([]);
        const user = data.login;
        dispatch(loginSuccess(user));
        navigate("/");
      },
      (errors) => {
        setLoading(false);
        setApiErrors(errors);
      }
    );
  }

  function handleChange(e) {
    setApiErrors([]);
    const propName = e.target.name;
    const newUser = {
      ...user,
      [propName]: e.target.value,
    };
    setUser(newUser);
    setErrors({
      ...errors,
      [propName]: validateLoginUserProp(propName, newUser),
    });
  }

  return (
    <>
      <Alert variant="danger" show={apiErrors.length > 0}>
        {apiErrors.map((err) => (
          <p key={err}>{err.message}</p>
        ))}
      </Alert>

      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Callsign</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your callsign"
            value={user.callsign}
            name="callsign"
            onChange={handleChange}
            isValid={errors.callsign === null}
            isInvalid={errors.callsign !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.callsign}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your password"
            value={user.password}
            name="password"
            onChange={handleChange}
            isValid={errors.password === null}
            isInvalid={errors.password !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>

        <Button type="button" disabled={formError || loading} onClick={login}>
          Login
        </Button>
      </Form>
    </>
  );
}
