import { combineReducers } from "redux";

import authReducer from "./auth-reducer";
import eventReducer from "./event-reducer";
import eventListReducer from "./event-list-reducer";
import usersReducer from "./users-reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  events: eventReducer,
  eventList: eventListReducer,
  users: usersReducer,
});

export default rootReducer;
