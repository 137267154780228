const initState = {
  currentEvents: [],
  futureEvents: [],
  pastEvents: [],
  myRoles: [],
};

const GETTING_EVENT_LIST = "GETTING_EVENT_LIST";
export function gettingEventList() {
  return { type: GETTING_EVENT_LIST };
}

const GET_EVENT_LIST_FAILURE = "GET_EVENT_LIST_FAILURE";
export function getEventListFailure() {
  return { type: GET_EVENT_LIST_FAILURE };
}

const GET_EVENT_LIST_SUCCESS = "GET_EVENT_LIST_SUCCESS";
export function getEventListSuccess(payload) {
  return {
    type: GET_EVENT_LIST_SUCCESS,
    payload,
  };
}

/*
  payload should be in shape like 
  {
    checked,
    name
  }
*/
const SELECT_MY_ROLE = "SELECT_MY_ROLE";
export function seleteMyRoles(payload) {
  return {
    type: SELECT_MY_ROLE,
    payload,
  };
}

export default function eventListReducer(state = initState, action) {
  const payload = action.payload;
  switch (action.type) {
    case SELECT_MY_ROLE:
      return {
        ...state,
        myRoles: payload.checked
          ? [...state.myRoles, payload.name]
          : state.myRoles.filter((value) => value !== payload.name),
      };

    case GETTING_EVENT_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_EVENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case GET_EVENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        currentEvents: payload.getCurrentEvents,
        futureEvents: payload.getFutureEvents,
        pastEvents: payload.getPastEvents,
      };

    default:
      return state;
  }
}
