export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const CHECKING_LOGIN = "CHECKING_LOGIN";
const REQUEST_OPERATE_SUCCESS = "REQUEST_OPERATE_SUCCESS";

export function requestOperateSuccess(payload) {
  return {
    type: REQUEST_OPERATE_SUCCESS,
    payload,
  };
}

export function checkingLogin() {
  return { type: CHECKING_LOGIN };
}

export function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
}

export function logoutSuccess(payload) {
  return {
    type: LOGOUT_SUCCESS,
    payload,
  };
}
const LOGIN_FAILURE = "LOGIN_FAILURE";
export function loginFailure() {
  return { type: LOGIN_FAILURE };
}

/*
  payload should has shape of {
    true/false
  }
  true means use local time zone
  false means use UTC
*/
const SWITCH_TIMEZONE = "SWITCH_TIMEZONE";
export function switchTimeZone(payload) {
  return {
    type: SWITCH_TIMEZONE,
    payload,
  };
}

const initState = {
  callsign: null,
  email: null,
  firstName: null,
  lastName: null,
  role: null,
  events: {},
  useLocalTime: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case CHECKING_LOGIN:
      return {
        ...state,
        checkingLogin: true,
      };

    case LOGIN_SUCCESS:
      let eventUsers = {};
      const events = action.payload.events;
      if (events) {
        eventUsers = events.reduce((acc, eu) => {
          acc[eu.eventID] = eu.eventRole;
          return acc;
        }, {});
      }
      return {
        ...state,
        checkingLogin: false,
        callsign: action.payload.callsign,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        role: action.payload.role,
        events: eventUsers,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        checkingLogin: false,
      };

    case LOGOUT_SUCCESS:
      return initState;

    case REQUEST_OPERATE_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.eventID]: action.payload.eventRole,
        },
      };

    case SWITCH_TIMEZONE:
      return {
        ...state,
        useLocalTime: action.payload,
      };

    default:
      return state;
  }
}
