import React, { useState } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { callAPI } from "../helpers/utils";
import { validateUserProp, isValidUser } from "../shared/user-schema";

export default function UserForm({ userObj, query, dataCB }) {
  let initUser = {
    callsign: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
  };

  if (userObj) {
    initUser = userObj;
  }

  const [user, setUser] = useState(initUser);

  const [errors, setErrors] = useState({
    callsign: null,
    email: null,
    firstName: null,
    lastName: null,
    password: null,
    confirmPassword: null,
  });

  const [apiErrors, setApiErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const validationError = Object.values(errors).reduce((prev, curr) => {
    return prev || curr !== null;
  }, false);
  const formError =
    !isValidUser(user) || validationError || apiErrors.length > 0;

  function submitUser() {
    if (user.confirmPassword !== user.password) {
      setErrors({ ...errors, confirmPassword: "Passwords don't match" });
      return;
    }

    setLoading(true);
    callAPI(
      query,
      user,
      (data) => {
        setLoading(false);
        setApiErrors([]);
        dataCB(data);
      },
      (errors) => {
        setLoading(false);
        setApiErrors(errors);
      }
    );
  }

  function handleChange(e) {
    setApiErrors([]);
    const propName = e.target.name;
    const newUser = {
      ...user,
      [propName]: e.target.value,
    };
    setUser(newUser);
    setErrors({
      ...errors,
      [propName]: validateUserProp(propName, newUser),
    });
  }

  return (
    <>
      <Alert variant="danger" show={apiErrors.length > 0}>
        {apiErrors.map((err) => (
          <p key={err}>{err.message}</p>
        ))}
      </Alert>

      <Form noValidate>
        <Form.Group className="mb-3">
          <Form.Label>Callsign</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your callsign"
            value={user.callsign}
            name="callsign"
            onChange={handleChange}
            isValid={errors.callsign === null}
            isInvalid={errors.callsign !== null}
            disabled={userObj}
          />
          <Form.Control.Feedback type="invalid">
            {errors.callsign}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your email"
            value={user.email}
            name="email"
            onChange={handleChange}
            isValid={errors.email === null}
            isInvalid={errors.email !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your first name"
            value={user.firstName}
            name="firstName"
            onChange={handleChange}
            isValid={errors.firstName === null}
            isInvalid={errors.firstName !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.firstName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your last name"
            value={user.lastName}
            name="lastName"
            onChange={handleChange}
            isValid={errors.lastName === null}
            isInvalid={errors.lastName !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.lastName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your password"
            value={user.password}
            name="password"
            onChange={handleChange}
            isValid={errors.password === null}
            isInvalid={errors.password !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
          <Form.Text>Password must be at least 8 characters long</Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your password again"
            value={user.confirmPassword}
            name="confirmPassword"
            onChange={handleChange}
            isValid={errors.confirmPassword === null}
            isInvalid={errors.confirmPassword !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.confirmPassword}
          </Form.Control.Feedback>
          <Form.Text>Please enter your password again</Form.Text>
        </Form.Group>
        <Button
          type="button"
          disabled={formError || loading}
          onClick={submitUser}
        >
          {userObj ? "Save" : "Register"}
        </Button>
      </Form>
    </>
  );
}
