import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export function Confirm({
  showStat,
  heading,
  body,
  actionName,
  actionnCallback,
  cancelCallBack,
  variant,
}) {
  function handleAction() {
    actionnCallback();
  }

  return (
    <Modal show={showStat} onHide={cancelCallBack}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelCallBack}>
          Cancel
        </Button>
        <Button variant={variant ? variant : "primary"} onClick={handleAction}>
          {actionName}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
