/*
  generate a key string from startTime (timestamp), band and mode
*/
function composeSlotID(eventID, band, mode, startTime) {
  let timestamp = startTime;
  if (parseInt(startTime) !== startTime) {
    timestamp = new Date(startTime).getTime();
  }
  return `${eventID}_${band}_${mode}_${timestamp}`;
}

module.exports = {
  composeSlotID,
};
