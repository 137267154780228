import { useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { changeUserRole, deleteUser } from "../helpers/user-helper";
import { Confirm } from "./confirm-box";
import { setCurrentCallsign } from "../store/users-reducer";

function ChangeUserRoleAction({ callsign, variant, actionName, newRole }) {
  return (
    <EditUserAction
      callsign={callsign}
      variant={variant}
      actionName={actionName}
      actionThunk={changeUserRole(callsign, newRole)}
    />
  );
}

function DeleteUserAction({ callsign }) {
  return (
    <EditUserAction
      callsign={callsign}
      variant="danger"
      actionName="Delete"
      actionThunk={deleteUser(callsign)}
    />
  );
}

function EditUserAction({ callsign, variant, actionName, actionThunk }) {
  const dispatch = useDispatch();
  const { callsign: myCallsign } = useSelector((state) => state.auth);
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <>
      <Button
        className="mx-1 my-1"
        variant={variant}
        disabled={callsign === myCallsign}
        onClick={() => setShowConfirm(true)}
      >
        {actionName}
      </Button>
      <Confirm
        showStat={showConfirm}
        heading="Please Confirm"
        body={`Are you sure you want to ${actionName} user: "${callsign}"?`}
        actionName={actionName}
        variant={variant}
        actionnCallback={() => {
          setShowConfirm(false);
          dispatch(actionThunk);
        }}
        cancelCallBack={() => setShowConfirm(false)}
      />
    </>
  );
}

function EnableUserAction({ callsign }) {
  return (
    <ChangeUserRoleAction
      callsign={callsign}
      variant="success"
      actionName="Enable"
      newRole="user"
    />
  );
}

function DisableUserAction({ callsign }) {
  return (
    <ChangeUserRoleAction
      callsign={callsign}
      variant="warning"
      actionName="Disable"
      newRole="disabled"
    />
  );
}

function PromoteUserAction({ callsign }) {
  return (
    <ChangeUserRoleAction
      callsign={callsign}
      variant="success"
      actionName="Promote"
      newRole="admin"
    />
  );
}

function DemoteUserAction({ callsign }) {
  return (
    <ChangeUserRoleAction
      callsign={callsign}
      variant="danger"
      actionName="Demote"
      newRole="user"
    />
  );
}

export function UserActions({ user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { callsign: myCallsign } = useSelector((state) => state.auth);

  function navigateToEditUser(callsign) {
    dispatch(setCurrentCallsign(callsign));
    navigate("/edit-user");
  }

  return (
    <>
      {user.role === "disabled" ? (
        <EnableUserAction callsign={user.callsign} />
      ) : (
        <>
          <DisableUserAction callsign={user.callsign} />
          {user.role === "admin" ? (
            <DemoteUserAction callsign={user.callsign} />
          ) : (
            <PromoteUserAction callsign={user.callsign} />
          )}
        </>
      )}
      <Button
        className="mx-1 my-1"
        onClick={() => navigateToEditUser(user.callsign)}
        disabled={user.callsign === myCallsign}
      >
        Edit
      </Button>
      <DeleteUserAction callsign={user.callsign} />
    </>
  );
}
