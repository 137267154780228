import React from "react";
import Table from "react-bootstrap/Table";

import { formatHourMinute, isCurrentSlot } from "../helpers/utils";
import { BandModeHeader, HourHeader, baseCellClassName } from "./slot-headers";
import { SlotCell } from "../components/slot-cell";
import { useSelector } from "react-redux";

function TimeVerticalTbody({ eventID, event, startTimes }) {
  const { useLocalTime } = useSelector((state) => state.auth);
  return (
    <tbody>
      {startTimes && event.bandModes
        ? startTimes.map((slotStartTime) => (
            <tr key={slotStartTime}>
              <th
                className={
                  isCurrentSlot(slotStartTime, event.slotLength)
                    ? "bg-warning " + baseCellClassName
                    : baseCellClassName
                }
              >
                {formatHourMinute(slotStartTime, useLocalTime)}
              </th>
              {event.bandModes.map((column) => (
                <SlotCell
                  key={`${column.band}_${column.mode}`}
                  startTime={slotStartTime}
                  band={column.band}
                  mode={column.mode}
                  eventID={eventID}
                  slotLength={event.slotLength}
                  modeIndex={column.modeIndex}
                ></SlotCell>
              ))}
            </tr>
          ))
        : null}
    </tbody>
  );
}

function TimeHorizontalTbody({ eventID, event, startTimes }) {
  return startTimes && event.bandModes ? (
    <tbody>
      {event.bands.map((band) => {
        return event.modes.map((mode, index) => {
          return (
            <tr
              key={`${band}${mode}`}
              className={index === 0 ? "border-tp" : ""}
            >
              {index === 0 ? (
                <th rowSpan={event.modes.length} className={baseCellClassName}>
                  {band}
                </th>
              ) : null}
              <th className={baseCellClassName}>{mode}</th>
              {startTimes.map((startTime) => {
                return (
                  <SlotCell
                    key={`${startTime}`}
                    startTime={startTime}
                    band={band}
                    mode={mode}
                    eventID={eventID}
                    slotLength={event.slotLength}
                  />
                );
              })}
            </tr>
          );
        });
      })}
    </tbody>
  ) : null;
}

export function SlotTable({ eventID, event, startTimes, timeHorizontal }) {
  return (
    <Table striped bordered hover size="sm">
      {timeHorizontal ? (
        <>
          <HourHeader startTimes={startTimes} slotLength={event.slotLength} />
          <TimeHorizontalTbody
            eventID={eventID}
            event={event}
            startTimes={startTimes}
          />
        </>
      ) : (
        <>
          <BandModeHeader bands={event.bands} modes={event.modes} />
          <TimeVerticalTbody
            eventID={eventID}
            event={event}
            startTimes={startTimes}
          />
        </>
      )}
    </Table>
  );
}
