import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/navbar";
import Container from "react-bootstrap/esm/Container";

import "./App.css";
import Login from "./pages/auth/login";
import Logout from "./pages/auth/logout";
import Register from "./pages/users/register";
import CreateEvent from "./pages/events/create-event";
import EditEvent from "./pages/events/edit-event";
import ManageEvent from "./pages/events/manage-event";
import RequestOperate from "./pages/event-users/request-operate";
import { EventList } from "./pages/event-list/event-list";
import { EventDetail } from "./pages/events/event-detail";
import EditProfile from "./pages/users/edit-profile";
import { ManageUsers } from "./pages/users/manage-users";
import { EditUser } from "./pages/users/edit-user";

import { checkLogin } from "./helpers/auth-helper";

function App() {
  const dispatch = useDispatch();
  dispatch(checkLogin);

  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Container>
          <Routes>
            <Route path="/" element={<EventList />} />
            <Route path="/event/:eventID/:date?" element={<EventDetail />} />
            <Route path="/create-event" element={<CreateEvent />} />
            <Route path="/edit-event/:eventID" element={<EditEvent />} />
            <Route path="/manage-event/:eventID" element={<ManageEvent />} />
            <Route
              path="/request-operate/:eventID"
              element={<RequestOperate />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register" element={<Register />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/manage-users" element={<ManageUsers />} />
            <Route path="/edit-user" element={<EditUser />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </>
  );
}

export default App;
