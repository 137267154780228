import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { callAPI } from "../../helpers/utils";
import { logoutSuccess } from "../../store/auth-reducer";
import { useDispatch } from "react-redux";

export default function Logout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    function logout() {
      const query = `
      mutation logout {
        logout
      }
    `;
      callAPI(query, null, (data) => {
        console.log("Logout: ", data.logout ? "Success" : "Failure");
        dispatch(logoutSuccess());
        navigate("/");
      });
    }
    logout();
  }, [dispatch, navigate]);

  return <></>;
}
