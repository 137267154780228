import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import UserForm from "../../components/user-form";
import { editUserSuccess } from "../../store/users-reducer";

export function EditUser(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, currentCallsign: callsign } = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    if (!callsign) {
      navigate("/manage-users");
    }
  }, [navigate, callsign]);

  const userObj = users.find((user) => user.callsign === callsign);
  if (!userObj) {
    return null;
  }

  const query = `
      mutation editUser(
        $callsign: ID!
        $email: String, 
        $firstName: String, 
        $lastName: String,
        $password: String
        $role: String
        ) {
          editUser(
            callsign: $callsign
            email: $email
            firstName: $firstName
            lastName: $lastName
            password: $password
            role: $role
          ) {
            callsign
            email
            firstName
            lastName
            role
            events {
              eventID
              eventRole
            }
          }
       } 
    `;

  function handleData(data) {
    const user = data.editUser;
    dispatch(editUserSuccess(user));
    navigate("/manage-users");
  }

  return (
    <>
      <h1 className="my-3">Edit User</h1>
      <UserForm userObj={userObj} query={query} dataCB={handleData} />
    </>
  );
}
