const { object, string } = require("yup");
const { GraphQLError } = require("graphql");

const { validateProp } = require("../util");

const userSchema = object({
  callsign: string().required(),
  password: string().required(),
});

function isValidLoginUser(userObject) {
  return userSchema.isValidSync(userObject);
}

function validateLoginUserProp(propName, user) {
  return validateProp(userSchema, propName, user);
}

function validateLoginUser(userObject) {
  try {
    userSchema.validateSync(userObject);
  } catch (e) {
    throw new GraphQLError(e.message);
  }
}

module.exports = {
  validateLoginUser,
  isValidLoginUser,
  validateLoginUserProp,
};
