const { object, string } = require("yup");
const { GraphQLError } = require("graphql");

const { validateProp } = require("../util");

const callsignPattern =
  /^([2-9A-Za-z]{1,3}\/)?[2-9A-Za-z]{1,3}\d[0-9A-Za-z]+(\/[0-9A-Za-z]+)?/;

const userSchema = object({
  callsign: string()
    .required()
    .matches(callsignPattern, `must be a valid callsign`),
  email: string().required().email(),
  firstName: string().required(),
  lastName: string(),
  password: string().min(8),
  confirmPassword: string().min(8),
});

function isValidUser(userObject) {
  return userSchema.isValidSync(userObject);
}

function validateUserProp(propName, user) {
  return validateProp(userSchema, propName, user);
}

function validateUser(userObject) {
  try {
    userSchema.validateSync(userObject);
  } catch (e) {
    throw new GraphQLError(e.message);
  }
}

module.exports = {
  validateUser,
  isValidUser,
  callsignPattern,
  validateUserProp,
};
