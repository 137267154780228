import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { EventCard } from "../../components/event-card";
import { retrieveEventList } from "../../helpers/event-helper";
import { seleteMyRoles } from "../../store/event-list-reducer";

export function EventList(props) {
  const { currentEvents, futureEvents, pastEvents, myRoles } = useSelector(
    (state) => state.eventList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveEventList(myRoles));
  }, [dispatch, myRoles]);

  function renderEvent(evt) {
    return (
      <Col lg="6" xl="4" xxl="3" key={evt.eventID}>
        <EventCard event={evt} />
      </Col>
    );
  }

  return (
    <>
      <Form className="my-4 text-end">
        <Form.Label>Filter by:</Form.Label>
        <Form.Check
          className="mx-3"
          inline
          label="Events I manage"
          name="manager"
          type="switch"
          checked={myRoles.includes("manager")}
          onChange={(e) =>
            dispatch(
              seleteMyRoles({
                checked: e.target.checked,
                name: e.target.name,
              })
            )
          }
        />
        <Form.Check
          inline
          className="mx-3"
          label="Events I participate in"
          name="operator"
          type="switch"
          checked={myRoles.includes("operator")}
          onChange={(e) =>
            dispatch(
              seleteMyRoles({
                checked: e.target.checked,
                name: e.target.name,
              })
            )
          }
        />
      </Form>

      {currentEvents.length === 0 &&
      futureEvents.length === 0 &&
      pastEvents.length === 0 ? (
        <h3>No events found in the system</h3>
      ) : null}
      {currentEvents.length > 0 ? <h1>Current Events</h1> : null}
      <Row>{currentEvents.map(renderEvent)}</Row>
      {futureEvents.length > 0 ? <h1>Future Events</h1> : null}
      <Row>{futureEvents.map(renderEvent)}</Row>
      {pastEvents.length > 0 ? <h1>Past Events</h1> : null}
      <Row>{pastEvents.map(renderEvent)}</Row>
    </>
  );
}
