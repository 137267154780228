import { callAPI } from "./utils";
import {
  loginSuccess,
  checkingLogin,
  loginFailure,
} from "../store/auth-reducer";

/*
  Thunk function to refresh login/user status
*/
export function checkLogin(dispatch, getState) {
  const state = getState();
  if (state.auth.checkingLogin) {
    console.log("already checking login");
    return;
  }
  dispatch(checkingLogin());
  const query = `mutation checkLogin { 
      checkLogin {
          callsign
          email
          firstName
          lastName
          role
          events {
            eventID
            eventRole
          }
      }
    }
    `;

  callAPI(
    query,
    null,
    (data) => {
      const user = data.checkLogin;
      if (user) {
        dispatch(loginSuccess(user));
      }
    },
    (errors) => {
      dispatch(loginFailure());
    }
  );
}
