import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/esm/Container";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NavBar() {
  const { callsign, role } = useSelector((state) => state.auth);
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      className="mb-4"
    >
      <Container>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav>
            <Navbar.Brand as={Link} to="/">
              Ham QTV
            </Navbar.Brand>
            <Nav.Link as={Link} to="/">
              Events
            </Nav.Link>
            {callsign ? (
              <Nav.Link as={Link} to="/create-event">
                New Event
              </Nav.Link>
            ) : null}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>{callsign ? `Welcome: ${callsign}` : null}</Navbar.Text>
          <Nav>
            {!callsign ? (
              <>
                <Nav.Link as={Link} to="/register">
                  Register
                </Nav.Link>
                <Nav.Link as={Link} to="/login">
                  Login
                </Nav.Link>
              </>
            ) : (
              <>
                {role === "admin" ? (
                  <Nav.Link as={Link} to="/manage-users">
                    Manage Users
                  </Nav.Link>
                ) : null}
                <Nav.Link as={Link} to="/edit-profile">
                  Profile
                </Nav.Link>
                <Nav.Link as={Link} to="/logout">
                  Logout
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
