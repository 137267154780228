import React from "react";
import { useParams } from "react-router-dom";

import EventForm from "../../components/event-form";
import { useEvent } from "./event-detail";

export default function EditEvent(props) {
  let { eventID } = useParams();
  eventID = parseInt(eventID);
  const { event } = useEvent(eventID);

  const query = `
    mutation editEvent(
      $eventID: Int!, 
      $eventCall: String,
      $startTime: String, 
      $endTime: String, 
      $slotLength: Int, 
      $bands: [String],
      $modes: [String],
      $title: String,
      $description: String 
      ) {

      editEvent(
          eventID: $eventID
          eventCall: $eventCall
          startTime: $startTime
          endTime: $endTime
          slotLength: $slotLength
          modes: $modes
          bands: $bands
          title: $title
          description: $description
      ) {
          eventID
          eventCall
          startTime
          endTime
          slotLength
      }
    }`;

  return event && event.title ? (
    <EventForm query={query} eventObj={event} />
  ) : null;
}
