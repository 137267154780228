const { object, string, number, date, boolean } = require("yup");
const { GraphQLError } = require("graphql");

const { validateProp } = require("../util");

const { callsignPattern } = require("./user-schema");

const eventSchema = object({
  eventCall: string()
    .required()
    .matches(callsignPattern, `Event callsign is not valid`),
  startTime: date().required().min("2023-01-01T00:00:00Z"),
  endTime: date().required().min("2023-01-01T00:00:00Z"),
  slotLength: number().integer().min(1).max(24).required(),
  title: string().required(),
  description: string(),

  start_date: date().required().min("2023-01-01"),
  end_date: date().required().min("2023-01-01"),
  start_time: string(),
  end_time: string(),
});

function isValidEvent(event) {
  return eventSchema.isValidSync(event);
}

function validateEventProp(propName, event) {
  return validateProp(eventSchema, propName, event);
}

function validateEvent(event) {
  try {
    eventSchema.validateSync(event);
  } catch (e) {
    throw new GraphQLError(e.message);
  }
}

module.exports = {
  isValidEvent,
  validateEvent,
  validateEventProp,
};
