import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import UserForm from "../../components/user-form";
import { loginSuccess } from "../../store/auth-reducer";

export default function Register(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = `
      mutation registerUser(
        $callsign: ID!, 
        $email: String!, 
        $firstName: String!, 
        $lastName: String, 
        $password: String!) {
          registerUser(
            callsign: $callsign
            email: $email
            firstName: $firstName
            lastName: $lastName
            password: $password
          ) {
            callsign
            email
            firstName
            lastName
            role
            events {
              eventID
              eventRole
            }
          }
       } 
    `;

  function handleData(data) {
    const user = data.registerUser;
    dispatch(loginSuccess(user));
    navigate("/");
  }

  return (
    <>
      <h1 className="my-3">Register</h1>
      <UserForm query={query} dataCB={handleData} />
    </>
  );
}
