import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import {
  callAPI,
  timestampToUTCString,
  isCurrentSlot,
  formatHour,
} from "../helpers/utils";
import { composeSlotID } from "../shared/slot-helper";
import { takeSlotSuccess, releaseSlotSuccess } from "../store/event-reducer";
import { Confirm } from "./confirm-box";
import { baseCellClassName } from "./slot-headers";

// Cell content component
function AvailableSlot(props) {
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();
  const { startTime, band, mode } = props;
  const slotParams = {
    ...props,
    startTime: timestampToUTCString(startTime),
  };

  function takeSlot() {
    setShowConfirm(false);
    const query = `
      mutation takeSlot($eventID: Int!, $band: String!, $mode: String!, $startTime: String!){
        takeSlot(eventID: $eventID, band: $band, mode: $mode, startTime: $startTime) {
          slotID
          eventID
          band
          mode
          startTime
          callsign
        }
      }
    `;
    callAPI(query, slotParams, (data) => {
      dispatch(takeSlotSuccess(data.takeSlot));
    });
  }

  function handleClick() {
    setShowConfirm(true);
  }

  return (
    <>
      <Button variant="outline-primary" size="sm" onClick={handleClick}>
        Take
      </Button>
      <Confirm
        showStat={showConfirm}
        heading="Please Confirm"
        body={`Are you sure you want to take ${band} ${mode} starts ${formatHour(
          startTime
        )}:00 UTC?`}
        actionName="Take"
        actionnCallback={takeSlot}
        cancelCallBack={() => setShowConfirm(false)}
      />
    </>
  );
}

function ReleasableSlot(props) {
  const { callsign, band, mode, startTime } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();

  function releaseSlot() {
    setShowConfirm(false);
    const query = `
      mutation releaseSlot($slotID: ID!, $eventID: Int!) {
          releaseSlot(
              slotID: $slotID
              eventID: $eventID
          ) {
              slotID
              eventID
              band
              mode
              startTime
              callsign
          }
      }
    `;
    callAPI(query, props, (data) => {
      dispatch(releaseSlotSuccess(data.releaseSlot));
    });
  }
  return (
    <>
      <Button variant="danger" size="sm" onClick={(e) => setShowConfirm(true)}>
        {callsign}
      </Button>
      <Confirm
        showStat={showConfirm}
        heading="Please Confirm"
        body={`Are you sure you want to release ${band} ${mode} starts ${formatHour(
          startTime
        )}:00 UTC?`}
        actionName="Release"
        actionnCallback={releaseSlot}
        cancelCallBack={() => setShowConfirm(false)}
      />
    </>
  );
}

// Slot Cell comonent
export function SlotCell({
  eventID,
  startTime,
  band,
  mode,
  slotLength,
  modeIndex,
}) {
  const slotKey = composeSlotID(eventID, band, mode, startTime);
  const endTime = startTime + slotLength * 60 * 60 * 1000;
  const pastSlot = endTime < Date.now();
  const event = useSelector((state) => state.events.events[eventID]);
  const {
    callsign: myCallsign,
    events: myEvents,
    role,
  } = useSelector((state) => state.auth);
  let eventRole;
  if (myEvents) {
    eventRole = myEvents[eventID];
  }
  if (!event.slots) {
    return null;
  }
  const slot = event.slots[slotKey];
  let callsign, slotID;
  if (slot) {
    callsign = slot.callsign;
    slotID = slot.slotID;
  }
  let content = "";

  // event operator or manager can take and release slots
  if (eventRole === "operator" || eventRole === "manager") {
    if (callsign) {
      if (pastSlot) {
        content = callsign;
      } else {
        // slot owner or manager can release a slot
        if (eventRole === "manager" || callsign === myCallsign) {
          content = (
            <ReleasableSlot
              slotID={slotID}
              eventID={eventID}
              band={band}
              mode={mode}
              startTime={startTime}
              callsign={callsign}
            />
          );
        } else {
          // other people's slot only show callsign
          content = callsign;
        }
      }
    } else {
      if (pastSlot) {
        content = null;
      } else {
        // available slot
        content = (
          <AvailableSlot
            eventID={eventID}
            band={band}
            mode={mode}
            startTime={startTime}
          />
        );
      }
    }
  } else {
    const colorCode = pastSlot ? "secondary" : "success";
    // public user can only see which slots are taken
    content = callsign ? (
      role === "admin" ? (
        callsign
      ) : (
        <Badge bg={colorCode} size="sm">
          QRV
        </Badge>
      )
    ) : null;
  }

  let className = baseCellClassName;
  if (isCurrentSlot(startTime, event.slotLength)) {
    className += " bg-warning";
  }
  if (modeIndex === 0) {
    className += " border-lft";
  }

  return (
    <td key={slotKey} className={className}>
      {content}
    </td>
  );
}
