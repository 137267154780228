export const config = {
  serverUrl: "/api/graphql",
  availableBands: [
    "10m",
    "12m",
    "15m",
    "17m",
    "20m",
    "30m",
    "40m",
    "80m",
    "160m",
  ],
  defaultBands: ["10m", "15m", "20m", "40m", "80m", "160m"],
  availableModes: ["CW", "Phone", "Digital"],
  defaultModes: ["CW", "Phone", "Digital"],
};
