import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "react-bootstrap/Table";

import { retrieveAllUsers } from "../../helpers/user-helper";
import { UserActions } from "../../components/user-actions";
import { timestampToDateOnlyString } from "../../helpers/utils";

export function ManageUsers() {
  const { role, callsign: myCallsign } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (role === "admin") {
      dispatch(retrieveAllUsers);
    }
  }, [role, dispatch]);

  return users.length === 0 || role !== "admin" ? null : (
    <>
      <h1 className="my-3">Manage Users ({users.length - 1})</h1>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Callsign</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Registered</th>
            <th>Updated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users
            ? users.map((user) =>
                user.callsign === myCallsign ? null : (
                  <tr key={user.callsign}>
                    <td>{user.callsign}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>{timestampToDateOnlyString(user.createdAt)}</td>
                    <td>{timestampToDateOnlyString(user.updatedAt)}</td>
                    <td>
                      <UserActions user={user} />
                    </td>
                  </tr>
                )
              )
            : null}
        </tbody>
      </Table>
    </>
  );
}
