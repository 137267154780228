import { React } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { timestampToDateOnlyString } from "../helpers/utils";

export function EventCard({ event }) {
  return (
    <Card className="mb-3" border="primary">
      <Card.Header className="h4">{event.eventCall}</Card.Header>
      <Card.Body>
        <Card.Title>
          <Link to={`/event/${event.eventID}`}>
            {event.title ? event.title : event.eventCall}
          </Link>
        </Card.Title>
        <Card.Text>
          {event.description || <i className="text-white">Description</i>}
        </Card.Text>
        <Row>
          <Col md={3} className="text-end">
            From:
          </Col>
          <Col>
            <strong>{timestampToDateOnlyString(event.startTime)}</strong>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="text-end">
            To:
          </Col>
          <Col>
            <strong>{timestampToDateOnlyString(event.endTime)}</strong>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
