import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import Table from "react-bootstrap/Table";

import { Confirm } from "../../components/confirm-box";
import { useEvent } from "./event-detail";
import { EventUserAction } from "../../components/event-user-actions";
import { deleteEvent } from "../../helpers/event-helper";
import { useDispatch, useSelector } from "react-redux";

export default function ManageEvent(props) {
  let { eventID } = useParams();
  const dispatch = useDispatch();
  eventID = parseInt(eventID);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  const { event, eventRole } = useEvent(eventID);
  const role = useSelector((state) => state.auth.role);

  if (!event) {
    return null;
  }

  return (
    <>
      <h1 className="text-center">
        {event.title}
        {eventRole === "manager" || role === "admin" ? (
          <>
            <Button as={Link} to={`/edit-event/${eventID}`} className="mx-3">
              Edit
            </Button>
            {role === "admin" ? (
              <>
                <Button onClick={() => setShowConfirm(true)} variant="danger">
                  Delete
                </Button>
                <Confirm
                  showStat={showConfirm}
                  variant="danger"
                  heading="Please Confirm"
                  body="Are you sure you want to delete this event?"
                  actionName="Delete"
                  actionnCallback={() => {
                    setShowConfirm(false);
                    dispatch(deleteEvent(navigate, eventID));
                  }}
                  cancelCallBack={() => setShowConfirm(false)}
                />
              </>
            ) : null}
          </>
        ) : null}
      </h1>
      <h3>Users</h3>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Callsign</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Message</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {event.users
            ? event.users.map((user) => (
                <tr key={user.callsign}>
                  <td>{user.callsign}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{user.message}</td>
                  <td>{user.eventRole}</td>
                  <td>
                    <EventUserAction
                      eventID={eventID}
                      callsign={user.callsign}
                      role={user.eventRole}
                    />
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    </>
  );
}
