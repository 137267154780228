const GET_EVENT_USER_SUCCESS = "GET_EVENT_USER_SUCCESS";
export function getEventUserSuccess(payload) {
  return {
    type: GET_EVENT_USER_SUCCESS,
    payload,
  };
}

const GET_EVENT_START = "GET_EVENT_START";
export function getEventStart(payload) {
  return {
    type: GET_EVENT_START,
    payload,
  };
}

const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";
export function getEventFailure(payload) {
  return {
    type: GET_EVENT_FAILURE,
    payload,
  };
}

const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export function getEventSuccess(payload) {
  return {
    type: GET_EVENT_SUCCESS,
    payload,
  };
}

const TAKE_SLOT_SUCCESS = "TAKE_SLOT_SUCCESS";
export function takeSlotSuccess(payload) {
  return {
    type: TAKE_SLOT_SUCCESS,
    payload,
  };
}

const RELEASE_SLOT_SUCCESS = "RELEASE_SLOT_SUCCESS";
export function releaseSlotSuccess(payload) {
  return {
    type: RELEASE_SLOT_SUCCESS,
    payload,
  };
}

const GET_EVENT_SLOTS_SUCCESS = "GET_EVENT_SLOTS_SUCCESS";
export function getEventSlotsSuccess(payload) {
  return {
    type: GET_EVENT_SLOTS_SUCCESS,
    payload,
  };
}

/*
  enumerate band/mode used as rows or columns
*/
function enumerateBandModes(bands, modes) {
  if (!bands || !modes) {
    return [];
  }
  const result = [];
  bands.forEach((band) => {
    modes.forEach((mode, modeIndex) => {
      result.push({
        band,
        mode,
        modeIndex,
      });
    });
  });
  return result;
}

const initState = {
  loading: {},
  events: {},
};

export default function eventReducer(state = initState, action) {
  let eventID;
  switch (action.type) {
    case GET_EVENT_START:
      return {
        ...state,
        loading: { ...state.loading, [action.payload]: true },
      };

    case GET_EVENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, [action.payload]: false },
      };

    case GET_EVENT_SUCCESS:
      const newEvent = action.payload;
      eventID = newEvent.eventID;
      const existingEvent = state.events[eventID] || {};
      return {
        ...state,
        loading: { ...state.loading, [eventID]: false },
        events: {
          ...state.events,
          [eventID]: {
            slots: existingEvent.slots || {},
            ...newEvent,
            bandModes: enumerateBandModes(newEvent.bands, newEvent.modes),
          },
        },
      };

    case GET_EVENT_SLOTS_SUCCESS:
      eventID = action.payload.eventID;
      const newSlots = action.payload.slots;
      if (!newSlots || newSlots.length === 0) {
        return state;
      }
      const existingSlots = state.events[eventID].slots || {};

      const slots = newSlots.reduce((curr, slot) => {
        curr[slot.slotID] = slot;
        return curr;
      }, existingSlots);

      return {
        ...state,
        // loading: { ...state.loading, [eventID]: false },
        events: {
          ...state.events,
          [eventID]: {
            ...state.events[eventID],
            slots,
          },
        },
      };

    case TAKE_SLOT_SUCCESS:
      eventID = action.payload.eventID;
      const slotEvent = state.events[eventID];
      return {
        ...state,
        events: {
          ...state.events,
          [eventID]: {
            ...slotEvent,
            slots: {
              ...slotEvent.slots,
              [action.payload.slotID]: action.payload,
            },
          },
        },
      };

    case RELEASE_SLOT_SUCCESS:
      eventID = action.payload.eventID;
      const { [action.payload.slotID]: _, ...cleanedSlots } =
        state.events[eventID].slots;
      return {
        ...state,
        events: {
          ...state.events,
          [eventID]: {
            ...state.events[eventID],
            slots: cleanedSlots,
          },
        },
      };

    case GET_EVENT_USER_SUCCESS:
      const { eventID: userEventID } = action.payload;
      const userEvent = state.events[userEventID];
      return {
        ...state,
        events: {
          ...state.events,
          [userEventID]: {
            ...userEvent,
            users: userEvent.users.map((user) => {
              if (user.callsign === action.payload.callsign) {
                return { ...user, ...action.payload };
              } else {
                return user;
              }
            }),
          },
        },
      };

    default:
      return state;
  }
}
