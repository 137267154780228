import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import { callAPI, localTimeStrToUTCStr } from "../helpers/utils";
import { isValidEvent } from "../shared/event-schema";
import { checkLogin } from "../helpers/auth-helper";
import {
  eventFormReducer,
  initState,
  setInitEvent,
  updateEventProperty,
  updateBandMode,
  updateProperty,
  newBandMode,
  submissionSuccess,
  submissionFailure,
  startSubmission,
} from "./event-form-reducer";

import { switchTimeZone } from "../store/auth-reducer";

function validStartTimes(useLocalTime) {
  return validTimeHelper("00:00", useLocalTime);
}

function validEndTimes(useLocalTime) {
  return validTimeHelper("59:59", useLocalTime);
}

function validTimeHelper(minutesSeconds, useLocalTime) {
  let times = [];
  for (let h = 0; h < 24; h++) {
    const hour_str = `${h}`.padStart(2, "0");
    times.push(`${hour_str}:${minutesSeconds}${useLocalTime ? "" : "Z"}`);
  }
  return times;
}

export default function EventForm({ eventObj, query }) {
  const { useLocalTime } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [state, dispatchForm] = useReducer(eventFormReducer, initState);
  if (eventObj && state.event.title === "") {
    dispatchForm(setInitEvent(eventObj));
  }

  const { event, errors, apiErrors, bands, modes, loading, newBand, newMode } =
    state;

  const validationError = Object.values(errors).reduce((prev, curr) => {
    return prev || curr !== null;
  }, false);

  const formError =
    !isValidEvent(event) || validationError || apiErrors.length > 0;

  const navigate = useNavigate();

  function submitEvent() {
    dispatchForm(startSubmission());
    callAPI(
      query,
      event,
      (data) => {
        dispatch(checkLogin);
        dispatchForm(submissionSuccess());
        navigate("/");
      },
      (errors) => {
        dispatchForm(submissionFailure(errors));
      }
    );
  }

  function handleChange(e) {
    const propName = e.target.name;
    let value = e.target.value;
    if (propName === "slotLength" && value) {
      value = parseInt(value);
    }
    dispatchForm(
      updateEventProperty({
        propName,
        value,
      })
    );
    const defaultStartTime = useLocalTime ? "00:00:00" : "00:00:00Z";
    const defaultEndTime = useLocalTime ? "23:59:59" : "23:59:59Z";
    if (propName === "start_date") {
      dispatchForm(
        updateEventProperty({
          propName: "startTime",
          value: localTimeStrToUTCStr(
            `${value}T${event.start_time || defaultStartTime}`
          ),
        })
      );
    } else if (propName === "end_date") {
      dispatchForm(
        updateEventProperty({
          propName: "endTime",
          value: localTimeStrToUTCStr(
            `${value}T${event.end_time || defaultEndTime}`
          ),
        })
      );
    } else if (propName === "start_time") {
      dispatchForm(
        updateEventProperty({
          propName: "startTime",
          value: localTimeStrToUTCStr(`${event.start_date}T${value}`),
        })
      );
    } else if (propName === "end_time") {
      dispatchForm(
        updateEventProperty({
          propName: "endTime",
          value: localTimeStrToUTCStr(`${event.end_date}T${value}`),
        })
      );
    }
  }

  return (
    <>
      <h1>{eventObj ? "Edit Event" : "Create Event"}</h1>
      <Alert variant="danger" show={apiErrors.length > 0}>
        {apiErrors.map((err) => (
          <p key={err}>{err.message}</p>
        ))}
      </Alert>
      <Form noValidate>
        <Form.Group className="mb-3">
          <Form.Label>Event Callsign</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter event callsign"
            value={event.eventCall}
            name="eventCall"
            onChange={handleChange}
            isValid={errors.eventCall === null}
            isInvalid={errors.eventCall !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.eventCall}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter event title"
            value={event.title}
            name="title"
            onChange={handleChange}
            isValid={errors.title === null}
            isInvalid={errors.title !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.title}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter event description"
            value={event.description}
            name="description"
            onChange={handleChange}
            isValid={errors.description === null}
            isInvalid={errors.description !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.description}
          </Form.Control.Feedback>
        </Form.Group>

        {/* <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            name="useLocalTime"
            label="Use Local Time"
            checked={useLocalTime}
            onChange={(e) => {
              dispatch(switchTimeZone(e.target.checked));
              dispatchForm(switchTimeZone(e.target.checked));
            }}
          ></Form.Check>
        </Form.Group> */}

        <Form.Group className="mb-3">
          <Form.Label>Start Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="date"
              placeholder="Enter event Start Date"
              value={event.start_date}
              name="start_date"
              onChange={handleChange}
              isValid={errors.startTime === null}
              isInvalid={errors.startTime !== null}
            />
            <Form.Select
              placeholder="Enter event Start Time"
              name="start_time"
              value={event.start_time}
              onChange={handleChange}
              isValid={errors.startTime === null}
              isInvalid={errors.startTime !== null}
              aria-label="Select start time"
            >
              {validStartTimes(useLocalTime).map((time) => {
                return (
                  <option key={time} value={time}>
                    {time}
                  </option>
                );
              })}
            </Form.Select>
            <InputGroup.Text>
              UTC
              {/* {useLocalTime
                ? `Local time (${
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  })`
                : "UTC"} */}
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {errors.start_date}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>End Date</Form.Label>
          <InputGroup>
            <Form.Control
              type="date"
              placeholder="Enter event End Date"
              value={event.end_date}
              name="end_date"
              onChange={handleChange}
              isValid={errors.endTime === null}
              isInvalid={errors.endTime !== null}
            />
            <Form.Select
              placeholder="Enter event End Time"
              name="end_time"
              value={event.end_time}
              onChange={handleChange}
              isValid={errors.endTime === null}
              isInvalid={errors.endTime !== null}
              aria-label="Select end time"
            >
              {validEndTimes(useLocalTime).map((time) => {
                return (
                  <option key={time} value={time}>
                    {time}
                  </option>
                );
              })}
            </Form.Select>
            <InputGroup.Text>
              UTC
              {/* {useLocalTime
                ? `Local time (${
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  })`
                : "UTC"} */}
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {errors.end_date}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Time Slot length</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter event time slot length in hours"
            value={event.slotLength}
            name="slotLength"
            onChange={handleChange}
            isValid={errors.slotLength === null}
            isInvalid={errors.slotLength !== null}
          />
          <Form.Control.Feedback type="invalid">
            {errors.slotLength}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="me-3">Bands:</Form.Label>
          {[...bands].map((band) => {
            return (
              <Form.Check
                key={band}
                inline
                label={band}
                name={band}
                type="checkbox"
                checked={event.bands.includes(band)}
                onChange={(e) =>
                  dispatchForm(
                    updateBandMode({
                      propName: "bands",
                      checked: e.target.checked,
                      name: e.target.name,
                    })
                  )
                }
              />
            );
          })}
          <InputGroup>
            <InputGroup.Text>New Band</InputGroup.Text>
            <Form.Control
              type="text"
              value={newBand}
              onChange={(e) =>
                dispatchForm(
                  updateProperty({ propName: "newBand", value: e.target.value })
                )
              }
            ></Form.Control>
            <Button
              variant="success"
              onClick={() => {
                dispatchForm(newBandMode({ propName: "bands", name: newBand }));
              }}
            >
              Add
            </Button>
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="me-3">Modes:</Form.Label>
          {[...modes].map((mode) => {
            return (
              <Form.Check
                key={mode}
                inline
                label={mode}
                name={mode}
                type="checkbox"
                checked={event.modes.includes(mode)}
                onChange={(e) =>
                  dispatchForm(
                    updateBandMode({
                      propName: "modes",
                      checked: e.target.checked,
                      name: e.target.name,
                    })
                  )
                }
              />
            );
          })}
          <InputGroup>
            <InputGroup.Text>New Mode</InputGroup.Text>
            <Form.Control
              type="text"
              value={newMode}
              onChange={(e) =>
                dispatchForm(
                  updateProperty({ propName: "newMode", value: e.target.value })
                )
              }
            ></Form.Control>
            <Button
              variant="success"
              onClick={() => {
                dispatchForm(newBandMode({ propName: "modes", name: newMode }));
              }}
            >
              Add
            </Button>
          </InputGroup>
        </Form.Group>

        <Button
          type="button"
          disabled={formError || loading}
          onClick={submitEvent}
        >
          {eventObj ? "Save" : "Create"}
        </Button>
      </Form>
    </>
  );
}
