import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";

import { callAPI } from "../helpers/utils";
import { getEventUserSuccess } from "../store/event-reducer";
import {
  approveRequest,
  grantManager,
  rejectRequest,
  revokeOperator,
  revokeManager,
} from "../helpers/event-user-functions";

export function EventUserAction({ eventID, callsign, role }) {
  const dispatch = useDispatch();
  const myCallsign = useSelector((state) => state.auth.callsign);

  function executeQuery([query, apiName]) {
    callAPI(query, { eventID, callsign }, (data) => {
      const user = data[apiName];
      dispatch(getEventUserSuccess(user));
      return user;
    });
  }

  switch (role) {
    case "":
      // New request
      return (
        <>
          <Button
            variant="success"
            className="mx-1 my-1"
            onClick={() => executeQuery(approveRequest())}
          >
            Approve
          </Button>

          <Button
            variant="danger"
            className="mx-1 my-1"
            onClick={() => executeQuery(rejectRequest())}
          >
            Reject
          </Button>
        </>
      );
    case "manager":
      // Manager
      return (
        <>
          <Button
            variant="danger"
            className="mx-1 my-1"
            disabled={myCallsign === callsign}
            onClick={() => executeQuery(revokeManager())}
          >
            Demote
          </Button>
          {/* <Button variant="danger">Revoke</Button> */}
        </>
      );
    case "operator":
      // Operator
      return (
        <>
          <Button
            className="mx-1 my-1"
            onClick={() => executeQuery(grantManager())}
          >
            Promote
          </Button>
          <Button
            variant="danger"
            className="mx-1 my-1"
            onClick={() => executeQuery(revokeOperator())}
          >
            Revoke
          </Button>
        </>
      );
    case "rejected":
      return (
        <Button
          variant="success"
          className="mx-1 my-1"
          onClick={() => executeQuery(approveRequest())}
        >
          Approve
        </Button>
      );
    default:
      return null;
  }
}
