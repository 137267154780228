import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import UserForm from "../../components/user-form";
import { loginSuccess } from "../../store/auth-reducer";

export default function EditProfile(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userObj = useSelector((state) => state.auth);
  if (!userObj || !userObj.callsign) {
    return null;
  }
  const query = `
      mutation editMyUser(
        $email: String!, 
        $firstName: String!, 
        $lastName: String,
        $password: String
        ) {
          editMyUser(
            email: $email
            firstName: $firstName
            lastName: $lastName
            password: $password
          ) {
            callsign
            email
            firstName
            lastName
            role
            events {
              eventID
              eventRole
            }
          }
       } 
    `;

  function handleData(data) {
    const user = data.editMyUser;
    dispatch(loginSuccess(user));
    navigate("/");
  }

  return (
    <>
      <h1 className="my-3">Edit Profile</h1>
      <UserForm userObj={userObj} query={query} dataCB={handleData} />
    </>
  );
}
