import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { retrievEventData } from "../../helpers/event-helper";
import { SlotTable } from "../../components/slot-table";
import {
  todayAsDateOnlyString,
  getHour,
  timestampToDateObj,
  formatDate,
} from "../../helpers/utils";

import { switchTimeZone } from "../../store/auth-reducer";

/*
  enumerate slot start times for date
*/
function enumerateSlotStartTimestamps(date, event, dates, useLocalTime) {
  const timestamps = [];
  if (date && event) {
    let startHour = 0;
    let endHour = 24;
    if (date === dates[0]) {
      startHour = getHour(event.startTime, useLocalTime);
      // adjust startHour to event's first hour
    }
    if (date === dates[dates.length - 1]) {
      // adjust endHour to event's last hour
      endHour = getHour(event.endTime, useLocalTime) + 1;
    }
    for (let h = startHour; h < endHour; h += event.slotLength) {
      // const time = `${h}:00Z`.padStart(6, "0");
      let time = `${h}:00`.padStart(5, "0");
      if (!useLocalTime) {
        time += "Z";
      }
      const slotDateObj = new Date(`${date}T${time}`);
      timestamps.push(slotDateObj.getTime());
    }
  }
  return timestamps;
}

/*
  enumerate all days from startTime until endTime in format yyyy-mm-dd
*/
function enumerateDates(startTime, endTime, useLocalTime = false) {
  const start = timestampToDateObj(startTime);
  const end = timestampToDateObj(endTime);

  let date = start;
  let result = [];
  while (date < end) {
    const year = useLocalTime ? date.getFullYear() : date.getUTCFullYear();
    const month = useLocalTime ? date.getMonth() : date.getUTCMonth();
    let day = useLocalTime ? date.getDate() : date.getUTCDate();
    result.push(formatDate(year, month, day));
    day++;
    date = new Date(
      useLocalTime ? new Date(year, month, day) : Date.UTC(year, month, day)
    );
  }

  return result;
}

export function useEvent(eventID, date) {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.events.events[eventID]);
  const {
    callsign: myCallsign,
    events: myEvents,
    role: userRole,
    useLocalTime,
  } = useSelector((state) => state.auth);
  let eventRole;
  if (myEvents) {
    eventRole = myEvents[eventID];
  }

  useEffect(() => {
    dispatch(retrievEventData(eventID, date));
  }, [eventID, date, dispatch]);
  return { event, eventRole, myCallsign, userRole, dispatch, useLocalTime };
}

export function EventDetail(props) {
  let { eventID, date } = useParams();
  eventID = parseInt(eventID);
  const navigate = useNavigate();

  const { event, eventRole, myCallsign, userRole, dispatch, useLocalTime } =
    useEvent(eventID, date);
  const [timeHorizontal, setTimeHorizontal] = useState(false);
  let dates = [];
  if (event) {
    dates = enumerateDates(event.startTime, event.endTime, useLocalTime);
  }

  useEffect(() => {
    if (!date && event) {
      // show the date closest to today
      const firstDay = dates[0];
      const lastDay = dates[dates.length - 1];
      const today = todayAsDateOnlyString(useLocalTime);
      let date = firstDay;
      if (today > firstDay && today <= lastDay) {
        date = today;
      } else if (today > lastDay) {
        date = lastDay;
      }
      navigate(`/event/${eventID}/${date}`);
    }
  }, [date, event, eventID, dates, useLocalTime, navigate]);

  // if (!date && event) {
  //   // show the date closest to today
  //   const firstDay = dates[0];
  //   const lastDay = dates[dates.length - 1];
  //   const today = todayAsDateOnlyString();
  //   let date = firstDay;
  //   if (today > firstDay && today <= lastDay) {
  //     date = today;
  //   } else if (today > lastDay) {
  //     date = lastDay;
  //   }
  //   navigate(`/event/${eventID}/${date}`);
  // }

  if (!event) {
    return null;
  }

  const showRequest =
    myCallsign && eventRole !== "manager" && eventRole !== "operator";
  const hasRequested = eventRole === "" || eventRole === "rejected";

  const startTimes = enumerateSlotStartTimestamps(
    date,
    event,
    dates,
    useLocalTime
  );
  return (
    <>
      <h1 className="text-center">
        {event.title}
        {eventRole === "manager" || userRole === "admin" ? (
          <Button as={Link} to={`/manage-event/${eventID}`} className="mx-3">
            Manage
          </Button>
        ) : null}
        {showRequest ? (
          <Button
            onClick={(e) => navigate(`/request-operate/${eventID}`)}
            disabled={hasRequested}
            className="mx-3"
          >
            Request to operate
          </Button>
        ) : null}
      </h1>

      {dates
        ? dates.map((d) => (
            <Button
              key={d}
              as={Link}
              to={`/event/${eventID}/${d}`}
              variant={d === date ? "outline-secondary" : "outline-primary"}
              size="sm"
              className="mx-1 my-3"
            >
              {d}
            </Button>
          ))
        : null}

      <div className="mb-3 text-end">
        {/* {useLocalTime ? (
          <Button
            className="mx-3"
            onClick={() => {
              dispatch(switchTimeZone(false));
            }}
          >
            Switch to UTC
          </Button>
        ) : (
          <Button
            className="mx-3"
            onClick={() => {
              dispatch(switchTimeZone(true));
            }}
          >
            Switch to Local Time
          </Button>
        )} */}
        <Button
          onClick={(e) => {
            setTimeHorizontal(!timeHorizontal);
          }}
        >
          Switch rows and columns
        </Button>
      </div>
      <SlotTable
        eventID={eventID}
        event={event}
        startTimes={startTimes}
        timeHorizontal={timeHorizontal}
      />
    </>
  );
}
