import axios from "axios";

import { config } from "../config";

// Call GraphQL API with callbacks
// errCB needs to accept errors array
// dataCB needs to accept GraphQL returned data
export async function callAPI(query, variables, dataCB, errCB) {
  if (!query || !dataCB) {
    return console.error(
      "Calling API without query, variables or data callback"
    );
  }
  const result = await callAPIHelper(query, variables);
  if (result.errors) {
    if (errCB) {
      errCB(result.errors);
    } else {
      console.log(result.errors);
    }
  } else {
    dataCB(result.data);
  }
}

// Call GraphQL API and return result
async function callAPIHelper(query, variables) {
  try {
    const { data } = await axios.post(config.serverUrl, {
      query,
      variables,
    });
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

// convert timestamp to UTC string
export function timestampToString(timestamp, useLocalTime = false) {
  if (useLocalTime) {
    return timestampToLocalString(timestamp);
  } else {
    return timestampToUTCString(timestamp);
  }
}

// convert timestamp to UTC string
export function timestampToUTCString(timestamp) {
  const date = timestampToDateObj(timestamp);
  return `${dateObjToDateOnlyString(date, false)}T${dateObjToTimeOnlyString(
    date,
    false
  )}`;
}

// convert timestamp to ISO formatted string in local timezone
export function timestampToLocalString(timestamp) {
  const date = timestampToDateObj(timestamp);
  return `${dateObjToDateOnlyString(date, true)}T${dateObjToTimeOnlyString(
    date,
    true
  )}`;
}

// convert timestamp to Date object
export function timestampToDateObj(timestamp) {
  return new Date(parseInt(timestamp));
}

// convert ISO formatted date time string to timestamp
export function timeStrToTimestamp(timeStr) {
  return new Date(timeStr).getTime();
}

// return given timestamp's date as string
export function timestampToDateOnlyString(timestamp, useLocalTime = false) {
  const date = timestampToDateObj(timestamp);
  return dateObjToDateOnlyString(date, useLocalTime);
}

// return given timestamp's time as string
export function timestampToTimeOnlyString(timestamp, useLocalTime = false) {
  const date = timestampToDateObj(timestamp);
  return dateObjToTimeOnlyString(date, useLocalTime);
}

// return given date object's date as string
export function dateObjToDateOnlyString(date, useLocalTime = false) {
  const year = useLocalTime ? date.getFullYear() : date.getUTCFullYear();
  const month = useLocalTime ? date.getMonth() : date.getUTCMonth();
  let day = useLocalTime ? date.getDate() : date.getUTCDate();
  return formatDate(year, month, day);
}

// return given date object's date as string
export function dateObjToTimeOnlyString(date, useLocalTime = false) {
  const hour = useLocalTime ? date.getHours() : date.getUTCHours();
  const minute = useLocalTime ? date.getMinutes() : date.getUTCMinutes();
  const second = useLocalTime ? date.getSeconds() : date.getUTCSeconds();
  return formatTime(hour, minute, second, useLocalTime);
}

// return string like yyyy-mm-dd, monthIndex is 0 based
export function formatDate(year, monthIndex, day) {
  const mm = `${monthIndex + 1}`.padStart(2, "0");
  const dd = `${day}`.padStart(2, "0");
  return `${year}-${mm}-${dd}`;
}

// return string like hh:mm:ss(Z)
export function formatTime(hour, minute, second, useLocalTime) {
  const hour_str = `${hour}`.padStart(2, "0");
  const minute_str = `${minute}`.padStart(2, "0");
  const second_str = `${second}`.padStart(2, "0");
  return `${hour_str}:${minute_str}:${second_str}${useLocalTime ? "" : "Z"}`;
}

// return today's date string
export function todayAsDateOnlyString(useLocalTime = false) {
  const today = Date.now();
  return timestampToDateOnlyString(today, useLocalTime);
}

/*
  return hour:00 string for given timestamp
 */
export function formatHourMinute(timestamp, useLocalTime = false) {
  const hour = getHour(timestamp, useLocalTime);
  return `${hour}:00`.padStart(5, "0");
}

/*
  return hour string for given timestamp
 */
export function formatHour(timestamp) {
  const hour = getHour(timestamp);
  return `${hour}`.padStart(2, "0");
}

/*
  return hour value for given timestamp
 */
export function getHour(timestamp, useLocalTime = false) {
  const dateObj = timestampToDateObj(timestamp);
  return useLocalTime ? dateObj.getHours() : dateObj.getUTCHours();
}

/*
  determine if current time is in slot designated by startTime and slotLength
*/

export function isCurrentSlot(startTime, slotLength) {
  const endTime = startTime + slotLength * 60 * 60 * 1000;
  const now = Date.now();

  return now >= startTime && now <= endTime;
}

export function localTimeStrToUTCStr(localTimeStr) {
  const date = new Date(localTimeStr);
  return timestampToUTCString(date.getTime());
}
