import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { callAPI } from "../../helpers/utils";
import { requestOperateSuccess } from "../../store/auth-reducer";
import { retrievEventData } from "../../helpers/event-helper";

export default function RequestOperate(props) {
  let { eventID } = useParams();
  eventID = parseInt(eventID);
  const myCallsign = useSelector((state) => state.auth.callsign);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [request, setRequest] = useState({ eventID, message: "" });

  if (!myCallsign) {
    navigate("/login");
    return;
  }

  function submitRequest() {
    const query = `
      mutation requestOperate($eventID: Int!, $message: String) {
        requestOperate(
            eventID: $eventID
            message: $message
        ) {
            eventID
            callsign
            eventRole
        }
      }`;
    callAPI(query, request, (data) => {
      const eventUser = data.requestOperate;
      dispatch(requestOperateSuccess(eventUser));
      dispatch(retrievEventData(eventID));
    });

    navigate(`/event/${eventID}`);
    return;
  }

  return (
    <>
      <h1>Request to operate</h1>
      <Form noValidate>
        <Form.Group className="mb-3">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Leave an optional message for event manager"
            value={request.message}
            name="message"
            onChange={(e) =>
              setRequest({ ...request, message: e.target.value })
            }
          />
        </Form.Group>

        <Button type="button" onClick={submitRequest}>
          Request
        </Button>
      </Form>
    </>
  );
}
