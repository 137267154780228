import { callAPI } from "./utils";
import {
  loadingUsers,
  loadUsersFailure,
  loadUsersSuccess,
  editUserSuccess,
  deleteUserSuccess,
} from "../store/users-reducer";

// Call API to get all users
export function retrieveAllUsers(dispatch, getState) {
  const loading = getState().users.loading;
  if (loading) {
    console.log("Already loading users, skip this time");
    return;
  }
  const query = `
    query getUsers {
      getAllUsers{
          callsign
          email
          firstName
          lastName
          role
          createdAt
          updatedAt
      }
    }
  `;
  dispatch(loadingUsers());
  callAPI(
    query,
    null,
    (data) => {
      const users = data.getAllUsers;
      dispatch(loadUsersSuccess(users));
    },
    (errors) => {
      console.log(errors);
      dispatch(loadUsersFailure());
    }
  );
}

// Disable a user
export function changeUserRole(callsign, role) {
  return (dispatch, getState) => {
    const query = `
      mutation editUser($callsign: ID!, $role: String) {
        editUser(callsign: $callsign, role: $role) {
            callsign
            email
            firstName
            lastName
            role
        }
      }
    `;
    callAPI(
      query,
      { callsign, role },
      (data) => {
        const user = data.editUser;
        dispatch(editUserSuccess(user));
      },
      (errors) => {
        console.log(errors);
      }
    );
  };
}

export function deleteUser(callsign) {
  return (dispatch, getState) => {
    const query = `
      mutation deleteUser($callsign: ID!) {
        deleteUser(callsign: $callsign) {
            callsign
            email
            firstName
            lastName
            role
        }
      }
    `;
    callAPI(
      query,
      { callsign },
      (data) => {
        const user = data.deleteUser;
        dispatch(deleteUserSuccess(user));
      },
      (errors) => {
        console.log(errors);
      }
    );
  };
}

export function resetPassword(callsign, password) {
  return (dispatch, getState) => {
    const query = `
      mutation editUser($callsign: ID!, $password: String) {
        editUser(callsign: $callsign, password: $password) {
            callsign
            email
            firstName
            lastName
            role
        }
      }
    `;
    callAPI(
      query,
      { callsign, password },
      (data) => {
        const user = data.editUser;
        dispatch(editUserSuccess(user));
      },
      (errors) => {
        console.log(errors);
      }
    );
  };
}
