import { callAPI } from "./utils";
import {
  getEventFailure,
  getEventStart,
  getEventSuccess,
  getEventSlotsSuccess,
} from "../store/event-reducer";

import {
  gettingEventList,
  getEventListFailure,
  getEventListSuccess,
} from "../store/event-list-reducer";
import { checkLogin } from "./auth-helper";

/*
  thunk generator to retrieve event detailed data
*/
export function retrievEventData(eventID, date) {
  return (dispatch, getState) => {
    const loadingEvent = getState().events.loading[eventID];
    if (loadingEvent) {
      console.log("one event is loading skip loading this time");
      return;
    }
    dispatch(getEventStart(eventID));
    const query = `
        query getEvent($eventID: Int!, $startDate: String) {
          getEvent(
              eventID: $eventID
          ) {
              eventID
              eventCall
              title
              description
              startTime
              endTime
              slotLength
              bands
              modes
              users {
                callsign
                email
                firstName
                lastName
                eventRole
                message
              }
          }
          getEventSlotsOfDate(
            eventID: $eventID
            startDate: $startDate
          ) {
              slotID
              eventID
              band
              mode
              startTime
              callsign
          }
        }
      `;

    callAPI(
      query,
      { eventID, startDate: date },
      (data) => {
        const newEvent = data.getEvent;
        dispatch(getEventSuccess(newEvent));
        const slots = data.getEventSlotsOfDate;
        dispatch(getEventSlotsSuccess({ eventID, slots }));
      },
      (errors) => {
        console.log(errors);
        dispatch(getEventFailure(eventID));
      }
    );
  };
}

/*
  thunk generator to retrieve event lists
*/
export function retrieveEventList(myRoles) {
  return (dispatch, getState) => {
    const { loading } = getState().eventList;
    if (loading) {
      console.log("Already loading event list, skip this time");
      return;
    }
    const query = `query getEvents($myRoles: [String], $first: Int, $offset: Int) {
      getCurrentEvents (myRoles: $myRoles, first: $first, offset: $offset) {
        eventID
        eventCall
        startTime
        endTime
        slotLength        
        title
        description
      }
      getFutureEvents (myRoles: $myRoles, first: $first, offset: $offset){
        eventID
        eventCall
        startTime
        endTime
        slotLength        
        title
        description
      }
      getPastEvents (myRoles: $myRoles, first: $first, offset: $offset){
        eventID
        eventCall
        startTime
        endTime
        slotLength        
        title
        description
      }
    }`;

    dispatch(gettingEventList());
    callAPI(
      query,
      { myRoles },
      (data) => {
        dispatch(getEventListSuccess(data));
      },
      (errors) => {
        dispatch(getEventListFailure());
        console.log(errors);
      }
    );
  };
}

/*
  Thunk function to delete an event
*/

export function deleteEvent(navigate, eventID) {
  return (dispatch, getState) => {
    const query = `
    mutation deleteEvent($eventID: Int!) {
      deleteEvent(
          eventID: $eventID
      ) {
          eventID
          eventCall
          startTime
          endTime
          slotLength
      }
    }
  `;

    callAPI(query, { eventID }, (data) => {
      dispatch(checkLogin);
      navigate("/");
    });
  };
}
