export function approveRequest() {
  const query = `
    mutation request($eventID: Int!, $callsign: ID!) {
        approveRequest(
            eventID: $eventID
            callsign: $callsign
        ) {
            eventID
            callsign
            eventRole
        }
    }
  `;
  return [query, "approveRequest"];
}

export function rejectRequest() {
  const query = `
    mutation request($eventID: Int!, $callsign: ID!) {
        rejectRequest(
            eventID: $eventID
            callsign: $callsign
        ) {
            eventID
            callsign
            eventRole
        }
    }
  `;
  return [query, "rejectRequest"];
}

export function revokeOperator() {
  const query = `
    mutation request($eventID: Int!, $callsign: ID!) {
        revokeOperator(
            eventID: $eventID
            callsign: $callsign
        ) {
            eventID
            callsign
            eventRole
        }
    }
  `;
  return [query, "revokeOperator"];
}

export function grantManager() {
  const query = `
    mutation request($eventID: Int!, $callsign: ID!) {
        grantManager(
            eventID: $eventID
            callsign: $callsign
        ) {
            eventID
            callsign
            eventRole
        }
    }
  `;
  return [query, "grantManager"];
}

export function revokeManager() {
  const query = `
    mutation request($eventID: Int!, $callsign: ID!) {
        revokeManager(
            eventID: $eventID
            callsign: $callsign
        ) {
            eventID
            callsign
            eventRole
        }
    }
  `;
  return [query, "revokeManager"];
}
