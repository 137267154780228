const { GraphQLError } = require("graphql");

// format callsign into all caps
function formatCallsign(callsign) {
  if (!callsign) {
    throw new GraphQLError("Callsign is required");
  }
  const cleanCallsign = callsign.trim().toUpperCase();
  return cleanCallsign;
}

// format email into all lower cases
function formatEmail(email) {
  if (!email) {
    throw new GraphQLError("Email is required");
  }
  return email.trim().toLowerCase();
}

// format following parameters
// email: to lower case
// callsign: to upper case
// eventCall: to upper case
function formatParameters(parameters) {
  const newParams = { ...parameters };
  if (parameters.callsign) {
    newParams.callsign = formatCallsign(parameters.callsign);
  }
  if (parameters.email) {
    newParams.email = formatEmail(parameters.email);
  }
  if (parameters.eventCall) {
    newParams.eventCall = formatCallsign(parameters.eventCall);
  }

  return newParams;
}

function validateProp(schema, propName, obj) {
  try {
    schema.validateSyncAt(propName, obj);
    return null;
  } catch (e) {
    return e.message;
  }
}

/*
  compare band in a meaningful way, base on "m", "cm" suffiixes
*/
function compareBand(a, b) {
  let valueA = parseMeterValue(a);
  let valueB = parseMeterValue(b);
  if (isNaN(valueA)) {
    return 1;
  } else if (isNaN(valueB)) {
    return -1;
  }
  return valueA - valueB;
}

/*
  parse value in meter, supports XXXm, XXXcm and XXXmm
*/
function parseMeterValue(name) {
  const cleanName = name.trim().toLowerCase();
  let value = parseFloat(cleanName);
  if (cleanName.endsWith("cm")) {
    value /= 100;
  } else if (cleanName.endsWith("mm")) {
    value /= 1000;
  }
  return value;
}

module.exports = {
  formatParameters,
  formatCallsign,
  formatEmail,
  validateProp,
  compareBand,
};
