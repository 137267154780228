import { config } from "../config";
import {
  timestampToDateOnlyString,
  timestampToUTCString,
  timestampToTimeOnlyString,
  timeStrToTimestamp,
} from "../helpers/utils";

import SWITCH_TIMEZONE from "../store/auth-reducer";

import { validateEventProp } from "../shared/event-schema";

export const initState = {
  event: {
    eventCall: "",
    slotLength: 1,
    startTime: "",
    endTime: "",
    bands: config.defaultBands,
    modes: config.defaultModes,
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
  },
  errors: {
    eventCall: null,
    startTime: null,
    start_date: null,
    start_time: null,
    endTime: null,
    end_date: null,
    end_time: null,
    slotLength: null,
    bands: null,
    modes: null,
    title: null,
    description: null,
  },
  bands: new Set(config.availableBands),
  modes: new Set(config.availableModes),
  newBand: "",
  newMode: "",
  apiErrors: [],
  loading: false,
};

/*
  payload should has shape of an event object
*/
const SET_INIT_EVENT = "SET_INIT_EVENT";
export function setInitEvent(payload) {
  return {
    type: SET_INIT_EVENT,
    payload,
  };
}

const START_SUBMISSION = "START_SUBMISSION";
export function startSubmission() {
  return {
    type: START_SUBMISSION,
  };
}

/*
  payload should be an array of api errors
*/
const SUBMISSION_FAILURE = "SUBMISSION_FAILURE";
export function submissionFailure(payload) {
  return {
    type: SUBMISSION_FAILURE,
    payload,
  };
}

const SUBMISSION_SUCCESS = "SUBMISSION_SUCCESS";
export function submissionSuccess() {
  return {
    type: SUBMISSION_SUCCESS,
  };
}

/*
  payload should has shape of {
    propName,
    checked,
    name
  }
*/
const UPDATE_BAND_MODE = "UPDATE_BAND_MODE";
export function updateBandMode(payload) {
  return {
    type: UPDATE_BAND_MODE,
    payload,
  };
}

/*
  payload should has shape of {
    propName,
    name
  }
*/
const NEW_BAND_MODE = "NEW_BAND_MODE";
export function newBandMode(payload) {
  return {
    type: NEW_BAND_MODE,
    payload,
  };
}

/*
  payload should has shape of {
    propName,
    value
  }
*/
const UPDATE_EVENT_PROPERTY = "UPDATE_EVENT_PROPERTY";
export function updateEventProperty(payload) {
  return {
    type: UPDATE_EVENT_PROPERTY,
    payload,
  };
}

/*
  payload should has shape of {
    propName,
    value
  }
*/
const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export function updateProperty(payload) {
  return {
    type: UPDATE_PROPERTY,
    payload,
  };
}

export function eventFormReducer(state, action) {
  const payload = action.payload;
  switch (action.type) {
    case SET_INIT_EVENT:
      return {
        ...state,
        event: {
          ...state.event,
          ...payload,
          startTime: timestampToUTCString(payload.startTime),
          endTime: timestampToUTCString(payload.endTime),
          start_date: timestampToDateOnlyString(payload.startTime),
          start_time: timestampToTimeOnlyString(payload.startTime),
          end_date: timestampToDateOnlyString(payload.endTime),
          end_time: timestampToTimeOnlyString(payload.endTime),
        },
        bands: new Set([...state.bands, ...payload.bands]),
        modes: new Set([...state.modes, ...payload.modes]),
      };

    case UPDATE_EVENT_PROPERTY:
      return {
        ...state,
        apiErrors: [],
        event: {
          ...state.event,
          [payload.propName]: payload.value,
        },
        errors: {
          ...state.errors,
          [payload.propName]: validateEventProp(payload.propName, {
            [payload.propName]: payload.value,
          }),
        },
      };

    case UPDATE_BAND_MODE:
      const oldValues = state.event[payload.propName];
      const newValues = payload.checked
        ? [...oldValues, payload.name]
        : oldValues.filter((value) => value !== payload.name);

      return {
        ...state,
        apiErrors: [],
        event: {
          ...state.event,
          [payload.propName]: newValues,
        },
      };

    case UPDATE_PROPERTY:
      return {
        ...state,
        [payload.propName]: payload.value,
      };

    case NEW_BAND_MODE:
      if (state[payload.propName].has(payload.name)) {
        return state;
      }
      return {
        ...state,
        event: {
          ...state.event,
          [payload.propName]: [...state.event[payload.propName], payload.name],
        },
        [payload.propName]: new Set([...state[payload.propName], payload.name]),
      };

    case START_SUBMISSION:
      return {
        ...state,
        loading: true,
      };

    case SUBMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        apiErrors: payload,
      };

    case SUBMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        apiErrors: [],
      };

    case SWITCH_TIMEZONE:
      const startTime = timeStrToTimestamp(state.event.startTime);
      const endTime = timeStrToTimestamp(state.event.endTime);
      return {
        ...state,
        event: {
          ...state.event,
          start_date: timestampToDateOnlyString(startTime, payload),
          start_time: timestampToTimeOnlyString(startTime, payload),
          end_date: timestampToDateOnlyString(endTime, payload),
          end_time: timestampToTimeOnlyString(endTime, payload),
        },
      };

    default:
      return state;
  }
}
