import { React } from "react";
import { formatHourMinute, isCurrentSlot } from "../helpers/utils";
import { useSelector } from "react-redux";

export const baseCellClassName = "text-center align-middle";

export function BandModeHeader({ bands, modes }) {
  const { useLocalTime } = useSelector((state) => state.auth);
  if (!bands || bands.length === 0 || !modes || modes.length === 0) {
    return null;
  }

  return (
    <thead>
      <tr>
        <th className={baseCellClassName} rowSpan={2}>
          {useLocalTime ? "Local" : "UTC"}
        </th>
        {bands.map((band, bandIndex) => (
          <th
            key={band}
            colSpan={modes.length}
            className={"border-lft " + baseCellClassName}
          >
            {band}
          </th>
        ))}
      </tr>
      <tr>
        {bands.map((band) =>
          modes.map((mode, modeIndex) => (
            <th
              key={mode}
              className={
                modeIndex === 0
                  ? "border-lft " + baseCellClassName
                  : baseCellClassName
              }
            >
              {mode}
            </th>
          ))
        )}
      </tr>
    </thead>
  );
}

export function HourHeader({ startTimes, slotLength }) {
  const { useLocalTime } = useSelector((state) => state.auth);
  if (!startTimes || startTimes.length === 0) {
    return null;
  }
  return (
    <thead>
      <tr>
        <th className={baseCellClassName} colSpan={2}>
          {useLocalTime ? "Local" : "UTC"}
        </th>
        {startTimes.map((startTime) => (
          <th
            key={startTime}
            className={
              isCurrentSlot(startTime, slotLength)
                ? "bg-warning " + baseCellClassName
                : baseCellClassName
            }
          >
            {formatHourMinute(startTime, useLocalTime)}
          </th>
        ))}
      </tr>
    </thead>
  );
}
